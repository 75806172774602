import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import axios from 'axios';
import CSVReader from 'react-csv-reader';
import JSONTree from 'react-json-tree';
import Select from 'react-select';
//import testdata from './testdata';
import Papa from 'papaparse';
import { Bar } from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormCheckbox,
  Collapse,
  ListGroup
} from 'shards-react';

const App = () => {
  const [tableData, setTableData] = useState({ data: [[]] });
  const [hasUploaded, setHasUploaded] = useState(false);
  const [nlpData, setNlpData] = useState();
  const [barChartData, setBarChartData] = useState({ xAxis: [], yAxis: [] });
  const [barChartAmount, setBarchartAmount] = useState(10);
  const [sentiment, setSentiment] = useState({ sentimentAmounts: {} });
  const [bestWorst, setBestWorst] = useState({ best: {}, worst: {} });
  const [bestWorstAmount, setBestWorstAmount] = useState(5);
  const [col, setCol] = useState(null);
  const [ignoreFirstRow, setIgnoreFirstRow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topicPopover, setTopicPopover] = useState({
    visible: false,
    inputs: []
  });
  const [bestPopover, setBestPopover] = useState({
    visible: false,
    inputs: []
  });
  const [worstPopover, setWorstPopover] = useState({
    visible: false,
    inputs: []
  });
  const [sentimentPopover, setSentimentPopover] = useState({
    visible: false,
    inputs: []
  });
  const [collapse, setCollapse] = useState({
    topics: false,
    sentiment: false,
    bestWorst: false
  });

  const backend_protocol =
    process.env.REACT_APP_BACKEND_HTTPS === 'TRUE' ? 'https://' : 'http://';

  const backend_url = `${backend_protocol}${process.env.REACT_APP_BACKEND_HOSTNAME}/predict`;

  useEffect(() => {
    if (nlpData !== undefined) {
      getSentimentData();
    }

    function getSentimentData() {
      const data = { ...nlpData };

      // put all sentiments into a single array and sort it
      const sentiments = data.data
        .map(d => {
          return d.sentiment;
        })
        .filter(s => s !== null)
        .sort();

      // if no sentiments found abort
      if (sentiments.length < 1) {
        return;
      }

      // calculate all sentiments
      const avgSentiment =
        sentiments.reduce((total, s) => {
          return total + s;
        }) / sentiments.length;
      const sentiment = {
        sentimentAmounts: {
          veryPositive: sentiments.filter(s => s >= 0.2).length,
          positive: sentiments.filter(s => s >= 0 && s < 0.2).length,
          negative: sentiments.filter(s => s >= -0.2 && s < 0).length,
          veryNegative: sentiments.filter(s => s < -0.2).length
        },
        avgSentiment: avgSentiment.toFixed(4),
        medianSentiment: sentiments[Math.floor(sentiments.length / 2)].toFixed(
          4
        ),
        minSentiment: Math.min(...sentiments).toFixed(4),
        maxSentiment: Math.max(...sentiments).toFixed(4)
      };
      setSentiment(sentiment);
    }
  }, [nlpData]);

  useEffect(() => {
    if (nlpData !== undefined && typeof barChartAmount === 'number') {
      getBarChartData();
    }

    function getBarChartData() {
      const data = { ...nlpData };
      // putting all topics into a single array
      const words = _.flatten(
        data.data.map(d => {
          return d.topics.map(e => {
            return e.toLowerCase();
          });
        })
      );
      // count duplicates and put into object
      let counts = {};
      words.forEach(x => {
        counts[x] = (counts[x] || 0) + 1;
      });
      // create 2D-Array, sort it and take the top 10 entries
      counts = Object.entries(counts)
        .sort((a, b) => {
          return b[1] - a[1];
        })
        .slice(0, barChartAmount);
      const yAxis = counts.map(c => {
        return c[1];
      });
      const xAxis = counts.map(c => {
        return c[0];
      });
      setBarChartData({ xAxis: xAxis, yAxis: yAxis });
    }
  }, [nlpData, barChartAmount]);

  useEffect(() => {
    if (nlpData !== undefined && typeof bestWorstAmount === 'number') {
      getBestAndWorst();
    }
    function getBestAndWorst() {
      const data = { ...nlpData };

      let topicSentiments = {};

      // take all topics and generate an array with all sentiments.
      // Then put those into an object
      data.data.forEach(d => {
        if (d.sentiment !== null) {
          d.topics.forEach(t => {
            const topic = t.toLowerCase();
            if (topicSentiments[topic] !== undefined)
              topicSentiments[topic] = [...topicSentiments[topic], d.sentiment];
            else topicSentiments[topic] = [d.sentiment];
          });
        }
      });

      // calaculate average Sentiment of each topic
      Object.keys(topicSentiments).forEach(key => {
        topicSentiments[key] =
          topicSentiments[key].reduce((total, s) => {
            return total + s;
          }) / topicSentiments[key].length;
      });

      const topicSentimentArray = Object.entries(topicSentiments);

      topicSentimentArray.sort(function (a, b) {
        return a[1] - b[1];
      });

      // get best and worst 5 ready for barchart
      const bestXAxis = topicSentimentArray
        .slice(topicSentimentArray.length - bestWorstAmount)
        .map(c => {
          return c[0];
        })
        .reverse();

      const bestYAxis = topicSentimentArray
        .slice(topicSentimentArray.length - bestWorstAmount)
        .map(c => {
          return c[1].toFixed(4);
        })
        .reverse();

      const worstXAxis = topicSentimentArray
        .slice(0, bestWorstAmount)
        .map(c => {
          return c[0];
        })
        .reverse();

      const worstYAxis = topicSentimentArray
        .slice(0, bestWorstAmount)
        .map(c => {
          return c[1].toFixed(4);
        })
        .reverse();

      const bestWorstData = {
        best: { x: bestXAxis, y: bestYAxis },
        worst: { x: worstXAxis, y: worstYAxis }
      };

      setBestWorst(bestWorstData);
    }
  }, [nlpData, bestWorstAmount]);

  const convertData = (data, fileInfo) => {
    if (data.length >= 3000) {
      toast.info(
        'Your dataset might be too big to process. If it does not work, try a smaller dataset.',
        {
          className: 'rounded px-2'
        }
      );
    }
    setTableData(data);
    setHasUploaded(true);
  };

  const predictData = async () => {
    setCol(null);
    setLoading(true);
    const data = [...tableData];

    // returns all entries from certain column
    const newData = data.map(row => {
      return row[col];
    });

    // filters for empty strings and undefined
    const filteredData = newData.filter(e => {
      return e !== '' && e !== undefined;
    });

    // ignores first row if user wants to
    if (ignoreFirstRow) filteredData.shift();

    // transform data into object
    const dataObject = { data: filteredData };

    // request server
    axios
      .post(backend_url, dataObject)
      .then(response => {
        setLoading(false);
        toast.success('Successfully analyzed data.', {
          className: 'rounded px-2'
        });
        setNlpData(response.data);
      })
      .catch(err => {
        setLoading(false);
        toast.error('Error processing data. Your dataset might be too big.', {
          className: 'rounded px-2'
        });
        console.log('Err: ' + err);
      });
  };

  const parseCSV = () => {
    const data = { ...nlpData };
    const newData = data.data.map(d => {
      return {
        ...d,
        sentiment: d.sentiment !== null ? d.sentiment.toFixed(5) : '',
        input: d.input.replace(';', '.')
      };
    });
    return Papa.unparse(
      { data: newData },
      { columns: ['input', 'sentiment', 'topics'] }
    );
  };

  const getTopicInputs = item => {
    if (item.length < 1) return;
    const index = item[0]._index;
    let topic = barChartData.xAxis[index];
    const inputData = nlpData.data.filter(d => {
      return d.topics.map(t => t.toLowerCase()).includes(topic);
    });

    const inputs = inputData.map(d => d.input);
    setTopicPopover({
      visible: true,
      inputs: inputs.splice(0, 5),
      topic: topic
    });
  };

  const getBestInputs = item => {
    if (item.length < 1) return;
    const index = item[0]._index;
    let topic = bestWorst.best.x[index];
    const inputData = nlpData.data.filter(d => {
      return d.topics.map(t => t.toLowerCase()).includes(topic);
    });
    const inputs = inputData.map(d => d.input);
    setBestPopover({
      visible: true,
      inputs: inputs.splice(0, 5),
      topic: topic
    });
  };

  const getWorstInputs = item => {
    if (item.length < 1) return;
    const index = item[0]._index;
    let topic = bestWorst.worst.x[index];
    const inputData = nlpData.data.filter(d => {
      return d.topics.map(t => t.toLowerCase()).includes(topic);
    });
    const inputs = inputData.map(d => d.input);
    setWorstPopover({
      visible: true,
      inputs: inputs.splice(0, 5),
      topic: topic
    });
  };

  const getSentimentInputs = sentiment => {
    let topic = sentiment;
    let inputData;
    switch (sentiment) {
      case 'Very positive':
        inputData = nlpData.data.filter(d => d.sentiment >= 0.2);
        break;
      case 'Positive':
        inputData = nlpData.data.filter(
          d => d.sentiment < 0.2 && d.sentiment >= 0
        );
        break;
      case 'Negative':
        inputData = nlpData.data.filter(
          d => d.sentiment >= -0.2 && d.sentiment < 0
        );
        break;
      case 'Very negative':
        inputData = nlpData.data.filter(d => d.sentiment < -0.2);
        break;
      default:
        break;
    }
    console.log(`${inputData}`);
    if (inputData !== undefined) {
      const inputs = inputData.map(d => d.input);
      setSentimentPopover({
        visible: true,
        inputs: inputs.splice(0, 5),
        sentiment: topic
      });
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Analyzing data. This might take a while..."
    >
      <div className="bg-light">
        <Navbar type="dark" theme="success">
          <Container>
            <NavbarBrand href="#">NLP-Analysis by stackOcean</NavbarBrand>
          </Container>
        </Navbar>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container
          className="mt-5"
          style={{ paddingBottom: '120px', minHeight: '90vh' }}
        >
          <Row>
            <Col>
              <Card>
                <CardHeader>Upload Your data</CardHeader>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <Form>
                        <FormGroup className="mb-0">
                          <CSVReader
                            label="Choose your file"
                            onFileLoaded={(data, fileInfo) =>
                              convertData(data, fileInfo)
                            }
                          />
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>

                  {hasUploaded && (
                    <>
                      <Row>
                        <Col>
                          <CardTitle className="mt-5">
                            Preview of your data
                          </CardTitle>
                          <div className="mt-3 position-relative">
                            <div className="overflow-auto">
                              <table
                                className="table mb-0"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <thead>
                                  <tr>
                                    {tableData[0].map((number, iter) => (
                                      <th key={iter}>Col #{iter}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData.slice(0, 4).map((row, rowIter) => (
                                    <tr key={rowIter}>
                                      {row.map((el, elIter) => (
                                        <td key={elIter}>{el}</td>
                                      ))}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td>...and {tableData.length - 4} more</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="mt-3">
                        <Col md={6}>
                          <FormCheckbox
                            className="mt-2"
                            checked={ignoreFirstRow}
                            onChange={() => setIgnoreFirstRow(!ignoreFirstRow)}
                          >
                            The first row is descriptive. (Will not be
                            considered)
                          </FormCheckbox>
                          <Select
                            placeholder="Select the column you want to analyse..."
                            options={tableData[0].map((number, iter) => {
                              return { value: iter, label: `#${iter}` };
                            })}
                            onChange={e => setCol(e.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            theme="success"
                            disabled={col === null || !hasUploaded}
                            onClick={() => predictData()}
                          >
                            Analyse Data
                          </Button>
                        </Col>
                        <Col className="align-self-end">
                          <Button
                            theme="secondary"
                            className="float-right"
                            disabled={!hasUploaded}
                            onClick={() => setHasUploaded(false)}
                          >
                            Reset File
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {typeof nlpData === 'object' && (
            <Card className="mt-5">
              <>
                <Row>
                  <Col>
                    <CardHeader>NLP Analysis</CardHeader>
                    <CardBody className="mt-2">
                      <CardTitle>Most mentioned topics</CardTitle>
                      <hr></hr>
                      <Row className="mb-3">
                        <Container>
                          <Button
                            className="btn btn-light"
                            onClick={() =>
                              setCollapse({
                                ...collapse,
                                topics: !collapse.topics
                              })
                            }
                          >
                            Explanation
                          </Button>
                          <Collapse open={collapse.topics}>
                            <div className="p-3 mt-3 border rounded d-inline-block">
                              <span>
                                These are the most mentioned topics throughout
                                your data.
                              </span>
                            </div>
                          </Collapse>
                        </Container>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <span>Number of topics: </span>
                          <Select
                            className="w-25 d-inline-block"
                            defaultValue={{ value: 10, label: 10 }}
                            options={[
                              { value: 10, label: 10 },
                              { value: 15, label: 15 },
                              { value: 20, label: 20 }
                            ]}
                            onChange={e => setBarchartAmount(e.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Bar
                            id="topic-chart"
                            data={{
                              labels: barChartData.xAxis,
                              datasets: [
                                {
                                  backgroundColor: 'rgba(30,119,235,0.2)',
                                  borderColor: 'rgba(30,119,235,1)',
                                  borderWidth: 2,
                                  hoverBackgroundColor: 'rgba(30,119,235,0.4)',
                                  hoverBorderColor: 'rgba(30,119,235,1)',
                                  data: barChartData.yAxis
                                }
                              ]
                            }}
                            options={{
                              onClick: (evt, item) => {
                                getTopicInputs(item);
                              },
                              legend: { display: false },
                              scales: {
                                yAxes: [
                                  {
                                    ticks: {
                                      beginAtZero: true
                                    }
                                  }
                                ],
                                xAxes: [
                                  {
                                    gridLines: {
                                      display: false
                                    }
                                  }
                                ]
                              }
                            }}
                          />
                          <Modal
                            centered={true}
                            target="#topic-chart"
                            size="lg"
                            className="overflow-auto"
                            open={topicPopover.visible}
                            toggle={() =>
                              setTopicPopover({
                                ...topicPopover,
                                visible: !topicPopover.visible
                              })
                            }
                          >
                            <ModalHeader className="py-4">
                              Topic: {topicPopover.topic}
                            </ModalHeader>
                            <ModalBody
                              style={{ height: 'auto', overflow: 'auto' }}
                            >
                              <ListGroup flush={false}>
                                {topicPopover.inputs.map((input, iter) => (
                                  <div>
                                    {input}
                                    <hr></hr>
                                  </div>
                                ))}
                              </ListGroup>

                              <div>
                                <Button
                                  className="btn btn-secondary float-right"
                                  onClick={() =>
                                    setTopicPopover({
                                      ...topicPopover,
                                      visible: !topicPopover.visible
                                    })
                                  }
                                >
                                  Close
                                </Button>
                              </div>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardBody className="mt-5">
                      <CardTitle>Sentiment Analysis</CardTitle>
                      <hr></hr>
                      <Row className="mb-4">
                        <Container>
                          <Button
                            className="btn btn-light"
                            onClick={() =>
                              setCollapse({
                                ...collapse,
                                sentiment: !collapse.sentiment
                              })
                            }
                          >
                            Explanation
                          </Button>
                          <Collapse open={collapse.sentiment}>
                            <div className="p-3 mt-3 border rounded d-inline-block">
                              <span>
                                These are the sentiments of your data. It is
                                displayed as a number between 1 and -1, where 1
                                is positive and -1 is negative.
                              </span>
                            </div>
                          </Collapse>
                        </Container>
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <span>Average Sentiment:</span>
                          <h4>{sentiment.avgSentiment}</h4>
                        </Col>
                        <Col>
                          <span>Median Sentiment:</span>
                          <h4>{sentiment.medianSentiment}</h4>
                        </Col>
                        <Col>
                          <span>Best Sentiment:</span>
                          <h4>{sentiment.maxSentiment}</h4>{' '}
                        </Col>
                        <Col>
                          <span>Worst Sentiment:</span>
                          <h4>{sentiment.minSentiment}</h4>{' '}
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col className="text-center">
                          <img
                            src={require('./img/satisfaction_3.png')}
                            alt=""
                            class="rounded mb-12 w-100 mb-2"
                            onClick={() => getSentimentInputs('Very positive')}
                          />
                          <div className="mt-3">
                            <span>Very positive sentiments: </span>
                            <h4>{sentiment.sentimentAmounts.veryPositive}</h4>
                          </div>
                        </Col>
                        <Col className="text-center">
                          <img
                            src={require('./img/satisfaction_2.png')}
                            alt=""
                            class="rounded mb-12 w-100 mb-2"
                            onClick={() => getSentimentInputs('Positive')}
                          />
                          <div className="mt-3">
                            <span>Positive sentiments: </span>
                            <h4>{sentiment.sentimentAmounts.positive}</h4>
                          </div>
                        </Col>
                        <Col className="text-center">
                          <img
                            src={require('./img/satisfaction_1.png')}
                            alt=""
                            class="rounded mb-12 w-100 mb-2"
                            onClick={() => getSentimentInputs('Negative')}
                          />
                          <div className="mt-3">
                            <span>Negative sentiments: </span>
                            <h4>{sentiment.sentimentAmounts.negative}</h4>
                          </div>
                        </Col>
                        <Col className="text-center">
                          <img
                            src={require('./img/satisfaction_0.png')}
                            alt=""
                            class="rounded mb-12 w-100 mb-2"
                            onClick={() => getSentimentInputs('Very negative')}
                          />
                          <div className="mt-3">
                            <span>Very negative sentiments: </span>
                            <h4>{sentiment.sentimentAmounts.veryNegative}</h4>
                          </div>
                        </Col>
                      </Row>
                      <Modal
                        centered={true}
                        size="lg"
                        className="overflow-auto"
                        open={sentimentPopover.visible}
                        toggle={() =>
                          setSentimentPopover({
                            ...sentimentPopover,
                            visible: !sentimentPopover.visible
                          })
                        }
                      >
                        <ModalHeader className="py-4">
                          Topic: {sentimentPopover.sentiment}
                        </ModalHeader>
                        <ModalBody style={{ height: 'auto', overflow: 'auto' }}>
                          <ListGroup flush={false}>
                            {sentimentPopover.inputs.map((input, iter) => (
                              <div>
                                {input}
                                <hr></hr>
                              </div>
                            ))}
                          </ListGroup>
                          <div>
                            <Button
                              className="btn btn-secondary float-right"
                              onClick={() =>
                                setSentimentPopover({
                                  ...sentimentPopover,
                                  visible: !sentimentPopover.visible
                                })
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Row>
                      <Col>
                        <CardBody className="py-0 pr-1">
                          <CardTitle>Best topics</CardTitle>
                          <hr></hr>
                        </CardBody>
                      </Col>

                      <Col>
                        <CardBody className="py-0 pl-1">
                          <CardTitle>Worst topics</CardTitle>
                          <hr></hr>
                        </CardBody>
                      </Col>
                    </Row>
                    <CardBody className="mb-4 py-0">
                      <Row>
                        <Container>
                          <Button
                            className="btn btn-light"
                            onClick={() =>
                              setCollapse({
                                ...collapse,
                                bestWorst: !collapse.bestWorst
                              })
                            }
                          >
                            Explanation
                          </Button>
                          <Collapse open={collapse.bestWorst}>
                            <div className="p-3 mt-3 border rounded d-inline-block">
                              <span>
                                These are the topics with the most positive/most
                                negative sentiments. It is displayed as a number
                                between 1 and -1, where 1 is positive and -1 is
                                negative.
                              </span>
                            </div>
                          </Collapse>
                        </Container>
                      </Row>
                    </CardBody>
                    <Row>
                      <Col md={6}>
                        <CardBody className="py-0">
                          <span>Number of topics: </span>
                          <Select
                            className="w-25 d-inline-block"
                            defaultValue={{ value: 5, label: 5 }}
                            options={[
                              { value: 5, label: 5 },
                              { value: 7, label: 7 },
                              { value: 10, label: 10 }
                            ]}
                            onChange={e => setBestWorstAmount(e.value)}
                          />
                        </CardBody>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-0">
                        <CardBody className="pr-1">
                          <Row>
                            <Col>
                              <Bar
                                data={{
                                  labels: bestWorst.best.x,
                                  datasets: [
                                    {
                                      label: 'sentiment',
                                      backgroundColor: 'rgba(23,198,113,0.2)',
                                      borderColor: 'rgba(23,198,113,1)',
                                      borderWidth: 2,
                                      hoverBackgroundColor:
                                        'rgba(23,198,113,0.4)',
                                      hoverBorderColor: 'rgba(23,198,113,1)',
                                      data: bestWorst.best.y
                                    }
                                  ]
                                }}
                                options={{
                                  onClick: (evt, item) => {
                                    getBestInputs(item);
                                  },
                                  legend: { display: false },
                                  scales: {
                                    yAxes: [
                                      {
                                        ticks: {
                                          stepSize: 0.5,
                                          min: -1,
                                          max: 1
                                        }
                                      }
                                    ],
                                    xAxes: [
                                      {
                                        gridLines: {
                                          display: false
                                        }
                                      }
                                    ]
                                  }
                                }}
                              />
                              <Modal
                                centered={true}
                                size="lg"
                                className="overflow-auto"
                                open={bestPopover.visible}
                                toggle={() =>
                                  setBestPopover({
                                    ...bestPopover,
                                    visible: !bestPopover.visible
                                  })
                                }
                              >
                                <ModalHeader className="py-4">
                                  Topic: {bestPopover.topic}
                                </ModalHeader>
                                <ModalBody
                                  style={{ height: 'auto', overflow: 'auto' }}
                                >
                                  <ListGroup flush={false}>
                                    {bestPopover.inputs.map((input, iter) => (
                                      <div>
                                        {input}
                                        <hr></hr>
                                      </div>
                                    ))}
                                  </ListGroup>
                                  <div>
                                    <Button
                                      className="btn btn-secondary float-right"
                                      onClick={() =>
                                        setBestPopover({
                                          ...bestPopover,
                                          visible: !bestPopover.visible
                                        })
                                      }
                                    >
                                      Close
                                    </Button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>

                      <Col className="pl-0 ">
                        <CardBody className="pl-1">
                          <Row>
                            <Col>
                              <Bar
                                data={{
                                  labels: bestWorst.worst.x,
                                  datasets: [
                                    {
                                      label: 'sentiment',
                                      backgroundColor: 'rgba(255,99,132,0.2)',
                                      borderColor: 'rgba(255,99,132,1)',
                                      borderWidth: 2,
                                      hoverBackgroundColor:
                                        'rgba(255,99,132,0.4)',
                                      hoverBorderColor: 'rgba(255,99,132,1)',
                                      data: bestWorst.worst.y
                                    }
                                  ]
                                }}
                                options={{
                                  onClick: (evt, item) => {
                                    getWorstInputs(item);
                                  },
                                  legend: { display: false },
                                  scales: {
                                    yAxes: [
                                      {
                                        ticks: {
                                          stepSize: 0.5,
                                          min: -1,
                                          max: 1
                                        }
                                      }
                                    ],
                                    xAxes: [
                                      {
                                        gridLines: {
                                          display: false
                                        }
                                      }
                                    ]
                                  }
                                }}
                              />
                              <Modal
                                centered={true}
                                size="lg"
                                className="overflow-auto"
                                open={worstPopover.visible}
                                toggle={() =>
                                  setWorstPopover({
                                    ...worstPopover,
                                    visible: !worstPopover.visible
                                  })
                                }
                              >
                                <ModalHeader className="py-4">
                                  Topic: {worstPopover.topic}
                                </ModalHeader>
                                <ModalBody
                                  style={{ height: 'auto', overflow: 'auto' }}
                                >
                                  <ListGroup flush={false}>
                                    {worstPopover.inputs.map((input, iter) => (
                                      <div>
                                        {input}
                                        <hr></hr>
                                      </div>
                                    ))}
                                  </ListGroup>
                                  <div>
                                    <Button
                                      className="btn btn-secondary float-right"
                                      onClick={() =>
                                        setWorstPopover({
                                          ...worstPopover,
                                          visible: !worstPopover.visible
                                        })
                                      }
                                    >
                                      Close
                                    </Button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardBody className="mt-3">
                      <CardTitle className="rounded-0">Raw Data</CardTitle>
                      <hr></hr>
                      <Row>
                        <Col>
                          <JSONTree
                            theme={{
                              tree: {
                                padding: '.75em 1.75em 1em 1.75em',
                                borderRadius: '.5rem'
                              }
                            }}
                            data={nlpData}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Button
                            theme="info"
                            className="mt-2 float-right"
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                              JSON.stringify(nlpData)
                            )}`}
                            download="nlpData.json"
                          >
                            Download Data as JSON
                          </Button>

                          <Button
                            theme="info"
                            className="mt-2 mr-3 float-right"
                            href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                              parseCSV()
                            )}`}
                            download="nlpData.csv"
                          >
                            Download Data as CSV
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>
                </Row>
              </>
            </Card>
          )}
        </Container>
        <div>
          <Navbar type="dark" theme="secondary">
            <Container>
              <Row>
                <Nav>
                  <NavItem>
                    <NavLink>
                      <a
                        className="text-white"
                        href="https://stackocean.com/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy
                      </a>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink>
                      <a
                        className="text-white"
                        href="https://stackocean.com/imprint.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Imprint
                      </a>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>

              <span className="copyright ml-auto my-auto mr-2 align-right text-white">
                © stackOcean GmbH
              </span>
            </Container>
          </Navbar>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default App;
